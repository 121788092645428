import React from "react";
import { Container, Flex } from "theme-ui";
import Reveal from "@solid-ui-components/Reveal";
import StackTitle from "@solid-ui-components/StackTitle";
import ListItem from "@solid-ui-components/ListItem";
import FlexImage from "@solid-ui-components/FlexImage";
import FlexContent from "@solid-ui-components/FlexContent";
import FlexOverlapFade from "@solid-ui-components/FlexOverlapFade";
import ContentImages from "@solid-ui-components/ContentImages";
import PlayerModal from "@solid-ui-blocks/Modal/PlayerModal";
import ContentButtons from "@solid-ui-components/ContentButtons";
import WithDefaultContent from "@solid-ui-blocks/WithDefaultContent";

const FeaturesWithPhotoBlock01 = ({
  content: { kicker, title, text_1, text_2, images, collection, video, buttons },
  reverse,
}) => (
  <Container sx={{ position: `relative` }}>
    <Flex
      sx={{
        alignItems: [null, `center`],
        flexDirection: [
          reverse ? `column-reverse` : `column`,
          reverse ? `row-reverse` : `row`,
        ],
        mx: [null, null, null, -4],
      }}
    >
      <FlexImage reverse={reverse}>
        <ContentImages
          content={{ images }}
          reverse={reverse}
          imgStyle={{ borderRadius: '12px' }}
        />
        {video?.src?.publicURL &&
          <div
            style={{
              position: "absolute",
              top: 'calc(50% - 24px)',
              left: 'calc(50% - 32px)'
            }}
          >
            <PlayerModal video={video} />
          </div>
        }
      </FlexImage>
      <FlexContent reverse={reverse}>
        <StackTitle
          kicker={kicker}
          title={title}
          lines={[text_1, text_2]}
          align={["center", "left"]}
          space={3}
        />
        <Reveal effect={reverse ? "fadeInRight" : "fadeInLeft"} duration={1.5}>
          {collection?.map((props, index) => (
            <ListItem key={`item-${index}`} {...props} />
          ))}
        </Reveal>
        {buttons && 
          <div style={{ marginTop: "-30px" }}>
            <ContentButtons content={buttons} space={3} />
          </div>
        }
      </FlexContent>
    </Flex>
    <FlexOverlapFade direction={reverse ? "ltr" : "rtl"} />
  </Container>
);

export default WithDefaultContent(FeaturesWithPhotoBlock01);

// {
//   "text": "Learn more",
//   "type": "MODAL",
//   "link": "authentication",
//   "variant": "secondary"
// }
